import { format } from 'date-fns';

interface Result {
  name: string;
  id: string;
}

export const generateNameAndId = (isDatabase = false, exportName: string | null = null): Result => {
  const date = format(new Date(), 'yyyyMMdd');
  let name = '';

  if (isDatabase) {
    name = `RB_database_${date}`;
  } else if (exportName) {
    name = `RB_${exportName}_${date}`;
  } else if (!exportName) {
    name = `RB_untitled_${date}`;
  }
  const id = `${name}-${Math.random() * Math.random()}`;
  return { name, id };
};
