import type { NextPage } from 'next';
import { Box, Alert, Typography } from '@mui/material';
import { useGlobalState } from '../../context/globalState';

interface Props {
  message: string;
  margin: number;
}

const ErrorMessages: NextPage<Props> = ({ message, margin = 0 }) => {
  const { openHelpPortal } = useGlobalState();

  return (
    <Box sx={{ marginRight: `${margin}px` }}>
      <Alert
        severity='error'
        variant='outlined'
        sx={{ background: '#fcf0f0', border: '1px solid #D32f2f !important', width: '100%', color: '#541313 !important' }}
      >
        {!message ? (
          <>
            <Typography>
              We seem to be experiencing some issues loading your data. Please
              {' '}
              <span
                className='database-error-text'
                onClick={() => typeof window !== 'undefined' && window.location.reload()}
              >
                refresh
              </span>.
            </Typography>
            <Typography>
              Should you continue to experience issues,
              {' '}
              <span
                className='database-error-text'
                onClick={() => openHelpPortal()}
              >
                contact us
              </span>.
            </Typography>
          </>
        ) : (
          <>{message}</>
        )}
      </Alert>
    </Box>
  );
};

export default ErrorMessages;
